import logo from 'assets/img/logo/ExpiTrack-black.png';
import SourceLink from 'components/SourceLink';
import React, { useEffect, useMemo, useState } from 'react';
import { RiBuilding4Line } from 'react-icons/ri';
import { MdDashboard, MdExitToApp, MdEventNote } from 'react-icons/md';
import { AiOutlineFlag } from 'react-icons/ai';
import { FaRegUserCircle, FaRoute } from 'react-icons/fa';

import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink, Input } from 'reactstrap';
import bn from 'utils/bemnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  logoutUser,
  changeOffice,
  getBranchOffices,
  logoutOfficeUser,
} from '../../store/actions/authAction';
import { useInfiniteSubOffices } from '../../apis/queries/branchOffices.queries';
import { debounce } from 'lodash';
import Select from '../shared/FormInputs/Select';

const sidebarBackground = {
  // backgroundImage: `url("${sidebarBgImage}")`,
  backgroundColor: `#fff`,

  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const getAccessibleMenuItems = role => {
  if (role === 'admin') {
    return [
      { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
      {
        to: '/offices',
        name: 'Offices',
        exact: true,
        Icon: RiBuilding4Line,
      },
      {
        to: '/agents',
        name: 'Agents',
        exact: true,
        Icon: FaRegUserCircle,
      },
      {
        to: '/locations',
        name: 'Locations',
        exact: true,
        Icon: AiOutlineFlag,
      },

      {
        to: '/routes',
        name: 'Routes',
        exact: true,
        Icon: FaRoute,
      },
      {
        to: '/reports',
        name: 'Reports',
        exact: true,
        Icon: MdEventNote,
      },
      {
        to: '/visits',
        name: 'Visits',
        exact: true,
        Icon: MdEventNote,
      },
      {
        to: '/location-types',
        name: 'Locations Types',
        exact: true,
        Icon: AiOutlineFlag,
      },
    ];
  } else if (role === 'office-manager') {
    return [
      { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
      {
        to: '/offices',
        name: 'Offices',
        exact: true,
        Icon: RiBuilding4Line,
      },
      {
        to: '/agents',
        name: 'Agents',
        exact: true,
        Icon: FaRegUserCircle,
      },
      {
        to: '/locations',
        name: 'Locations',
        exact: true,
        Icon: AiOutlineFlag,
      },
      {
        to: '/routes',
        name: 'Routes',
        exact: true,
        Icon: AiOutlineFlag,
      },
      {
        to: '/reports',
        name: 'Reports',
        exact: true,
        Icon: MdEventNote,
      },
      {
        to: '/visits',
        name: 'Visits',
        exact: true,
        Icon: MdEventNote,
      },
    ];
  } else {
    return [];
  }
};
const officeManagerToken = localStorage.getItem('office-manager-access-token');

const bem = bn.create('sidebar');
const Sidebar = () => {
  const userData = useSelector(state => state.auth.userData);
  const officeId = useSelector(state => state.auth.brach_office_id);
  const dispatch = useDispatch();
  const navItems = getAccessibleMenuItems(userData.role);
  const branchOfficesQuery = useInfiniteSubOffices(
    {
      limit: 10,
      search: searchableOfficeInput || undefined,
      page: 1,
      id: userData.officeId,
    },
    !!userData.officeId,
  );

  const [searchableOfficeInput, setSearchableOfficeInput] = useState(undefined);

  useEffect(() => {
    if (userData.role === 'office-manager') {
      dispatch(changeOffice(userData.officeId));
    }
  }, [userData]);

  const onChangeOffice = value => {
    dispatch(changeOffice(value));
  };

  const loadMoreBranchOffices = event => {
    branchOfficesQuery.hasNextPage && branchOfficesQuery.fetchNextPage();
    event.stopPropagation();
  };

  const debouncedSearchableOfficeInput = debounce(
    value => setSearchableOfficeInput(value),
    500,
  );

  const handleSearchableOfficeInput = value =>
    debouncedSearchableOfficeInput(value);

  const memoizedBranchOffices = useMemo(() => {
    const array =
      branchOfficesQuery.data &&
      branchOfficesQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));

    return array &&
      branchOfficesQuery &&
      branchOfficesQuery.data &&
      branchOfficesQuery.data.pages[0].data[0]
      ? array.concat([
          {
            value: branchOfficesQuery.data.pages[0].data[0].parentOffice._id,
            label: branchOfficesQuery.data.pages[0].data[0].parentOffice.name,
          },
        ])
      : array
      ? array
      : [];
  }, [branchOfficesQuery.data]);

  return (
    <aside className={bem.b()}>
      <div className={bem.e('background')} style={sidebarBackground} />
      <div className={bem.e('content')}>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={logo}
              width="150"
              height="30"
              className="pr-2 mt-2"
              alt=""
            />
          </SourceLink>
        </Navbar>
        {userData.role === 'office-manager' && branchOfficesQuery.data && (
          <Navbar>
            {/* <Input
              type="select"
              name="select"
              id="exampleSelect"
              onChange={e => onChangeOffice(e.target.value)}
            >
              {branchOffices.map(items => (
                <option key={items.id} value={items.id}>
                  {items.name}
                </option>
              ))}
            </Input> */}

            <Select
              options={memoizedBranchOffices}
              loadMore={loadMoreBranchOffices}
              placeholder="Select Office"
              onChange={val => {
                onChangeOffice(val.value);
              }}
              isLoading={branchOfficesQuery.isFetching}
              hasNextPage={branchOfficesQuery.hasNextPage}
              onInputChange={handleSearchableOfficeInput}
              isClearable={false}
              value={
                memoizedBranchOffices.filter(item => item.value === officeId)[0]
              }
            />
          </Navbar>
        )}

        <Nav vertical>
          {navItems.map(({ to, name, exact, Icon }, index) => (
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className="text-capitalize"
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                <span className="">{name}</span>
              </BSNavLink>
              <hr />
            </NavItem>
          ))}
          {officeManagerToken ? (
            <NavItem>
              <BSNavLink
                id={`navItem-signout`}
                className="text-capitalize"
                // tag={NavLink}
                onClick={() => dispatch(logoutOfficeUser(userData.role))}
              >
                <MdExitToApp className="mr-1" /> Signout from office
              </BSNavLink>
            </NavItem>
          ) : (
            <NavItem>
              <BSNavLink
                id={`navItem-signout`}
                className="text-capitalize"
                // tag={NavLink}
                onClick={() => dispatch(logoutUser(userData.role))}
              >
                <MdExitToApp className="mr-1" /> Signout
              </BSNavLink>
            </NavItem>
          )}
        </Nav>
      </div>
    </aside>
  );
  // }
};

export default Sidebar;
